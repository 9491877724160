import * as React from "react";
import { graphql, Link } from "gatsby";
import { BlockListFragment } from "../generated/graphql";
import { VerticalWrapPanel } from "../components/verticalWrapPanel";
import { ClassNames } from "@rocketmakers/armstrong-edge";

import * as styles from "./blockList.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";

interface IProps {
  slice: BlockListFragment;
}

export const BlockList: React.FC<IProps> = ({ slice }) => {
  return (
    <div id="block-list" className={ClassNames.concat("fixed-width", styles.blockList)}>
      <h3>{slice.primary.title}</h3>
      <VerticalWrapPanel
        maxRows={4}
        verticalGap={20}
        packing="tight"
        horizontalGap={100}
      >
        {slice.items.map((li) => (
          <div key={li.list_item_title} className="compact-list-item" data-no-data={!li.list_item_title}>
            {/* {li.list_item_image && li.list_item_image.gatsbyImageData && <GatsbyImage alt={li.list_item_image.alt || 'List item icon'} image={li.list_item_image.gatsbyImageData}/>} */}
            {li.list_item_image.url && <img src={li.list_item_image.url} alt={li.list_item_image?.alt || ''}/>}
            <div>
              <strong>{li.list_item_title}</strong>
              <div>{li.list_item_description}</div>
            </div>
          </div>
        ))}
      </VerticalWrapPanel>
    </div>
  );
};

export const query = graphql`
  fragment BlockList on PrismicHomePageDataBodyBlockList {
    primary {
      display_mode
      title
    }
    items {
      list_item_title
      list_item_description
      list_item_image {
        alt
        gatsbyImageData
        url
      }
    }
  }
`;
