import * as React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FullWidthImageFragment } from "../generated/graphql";

import * as styles from "./fullWidthImage.module.scss";
import { ClassNames } from "@rocketmakers/armstrong-edge";

interface IProps {
  slice: FullWidthImageFragment;
}

export const FullWidthImage: React.FC<IProps> = ({ slice }) => {
  return (
    <div className={ClassNames.concat("fixed-width", "fw-image", styles.fullWidthImage)}>
      <GatsbyImage
        key={slice.primary.full_width_image_image?.alt}
        image={slice.primary.full_width_image_image?.gatsbyImageData}
        alt={slice.primary.full_width_image_image?.alt || "Image"}
      />      
    </div>
  );
};

export const query = graphql`
  fragment FullWidthImage on PrismicHomePageDataBodyFullWidthImage {
    primary {
      full_width_image_image {
        gatsbyImageData
        alt
      }
    }
  }
`;
