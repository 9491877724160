import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { ColumnLayoutFragment } from "../generated/graphql";
import { ColumnLayout } from "../components/columnLayout";

interface IProps {
  slice: ColumnLayoutFragment;
}

export const ColumnLayoutSlice: React.FC<IProps> = ({ slice }) => {
  return <ColumnLayout items={slice.items}/>
};

export const query = graphql`
  fragment ColumnLayout on PrismicStudiosOverviewPageDataBodyColumnLayout {
    items {
      image {
        gatsbyImageData
        alt
      }
      title
      description
    }
  }
`;
