import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { HeaderSubheaderFragment } from "../generated/graphql";

import * as styles from "./headerAndSubheader.module.scss";

interface IProps {
  slice: HeaderSubheaderFragment;
}

export const HeaderAndSubheader: React.FC<IProps> = ({ slice }) => {
  return (
    <div className={styles.headerAndSubheader}>
      <h2>{slice.primary.header}</h2>
      <p className="intro">{slice.primary.subheader}</p>
    </div>
  );
};

export const query = graphql`
  fragment HeaderSubheader on PrismicStudiosOverviewPageDataBodyHeaderSubheader {
    primary {
      header
      subheader
    }
  }
`;
