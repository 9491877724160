import { MapComponent } from "../components/mapComponent";
import { BigHeading } from "./bigHeading";
import { BlockList } from "./blockList";
import { ColumnLayoutSlice } from "./columnLayoutSlice";
import { CopyBlockOptionalImage } from "./copyBlockOptionalImage";
import { FullWidthImage } from "./fullWidthImage";
import { HeaderAndSubheader } from "./headerAndSubheader";
import { SwitchableImage } from "./switchableImage";

export const SliceComponents = {
  header___subheader: HeaderAndSubheader,
  column_layout: ColumnLayoutSlice,
  copy_block__optional_image: CopyBlockOptionalImage,
  full_width_image: FullWidthImage,
  block_list: BlockList,
  switchable_image: SwitchableImage,
  big_heading: BigHeading,
  site_map: MapComponent
};
