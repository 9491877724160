import * as React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { SwitchableImageFragment } from "../generated/graphql";
import { ClassNames, useMatchMedia } from "@rocketmakers/armstrong-edge";

import * as styles from "./switchableImage.module.scss";

interface IProps {
  slice: SwitchableImageFragment;
}

export const SwitchableImage: React.FC<IProps> = ({ slice }) => {
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);
  const selectedImage = React.useMemo(() => slice.items[selectedImageIndex], [selectedImageIndex]);
  const isMobile = useMatchMedia('(max-width: 900px)');

  return (
    <div id="switchable-image" className={ClassNames.concat("fixed-width", styles.switchableImage)}>
      {!isMobile && <>
        <div className={styles.headerBlock}>
          {slice.items.map((item, index) =>
            <div key={item.s_image_title} data-selected={selectedImageIndex === index} onClick={() => setSelectedImageIndex(index)}>
              <h3>{item.s_image_title}</h3>
            </div>
          )}
        </div>
        <div className={styles.imageBlock}>
          <GatsbyImage
            key={selectedImage.s_image?.alt}
            image={selectedImage.s_image?.gatsbyImageData}
            alt={selectedImage.s_image?.alt || "Image"}
          />
          <p>
            {selectedImage.s_image_copy}
          </p>
        </div>
      </>
      }
      {isMobile &&
        slice.items.map(item =>
          <div key={`image_item_${item.s_image_title}`}>
            <GatsbyImage
              key={item.s_image?.alt}
              image={item.s_image?.gatsbyImageData}
              alt={item.s_image?.alt || "Image"}
            />
            <h3>{item.s_image_title}</h3>
            <p>
              {selectedImage.s_image_copy}
            </p>
          </div>
        )
      }
    </div>
  );
};

export const query = graphql`
  fragment SwitchableImage on PrismicHomePageDataBodySwitchableImage {
    items {
      s_image {
        gatsbyImageData
        alt
      }
      s_image_title
      s_image_copy
    }
  }
`;
