import { graphql, Link, PageProps } from "gatsby";
import * as React from "react";
import { HeroSwiper } from "../../components/heroSwiper";
import Shell from "../../layout/shell";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import { SliceComponents } from "../../slices/slices";
import { StudiosOverviewPageQuery } from "../../generated/graphql";

import * as styles from "./index.module.scss";
import { ClassNames } from "@rocketmakers/armstrong-edge";

const Index: React.FC<PageProps<StudiosOverviewPageQuery>> = (pageProps) => {
  const data = pageProps.data.prismicStudiosOverviewPage?.data;
  if (!data) {
    return null;
  }
  return (
    <Shell rootActive={true}>
      <div className={ClassNames.concat("fixed-width", styles.callout)}>        
        <PrismicRichText field={data.callout_content?.richText} />
        <Link className="button alt" to={data.callout_page_link || "/"}>{data.callout_link_text}</Link>
      </div>
      <HeroSwiper>
        {data.hero_gallery?.map((img) => (
          <GatsbyImage
            key={img?.image?.alt}
            image={img?.image?.gatsbyImageData}
            alt={img?.image?.alt || "Hero image"}
          />
        ))}
      </HeroSwiper>
      <div className="fixed-width studios-body">
        <SliceZone slices={data.body} components={SliceComponents as any} />
      </div>
    </Shell>
  );
};

export const query = graphql`
  query StudiosOverviewPage {
    prismicStudiosOverviewPage {
      data {
        callout_link_text
        callout_page_link
        callout_content {
          richText
        }
        body {
          ... on PrismicStudiosOverviewPageDataBodyColumnLayout {
            id
            slice_type
            items {
              title
              description
              image {
                gatsbyImageData
                alt
              }
            }
          }
          ... on PrismicStudiosOverviewPageDataBodyHeaderSubheader {
            id
            slice_type
            primary {
              header
              subheader
            }
          }
        }
        hero_gallery {
          image {
            alt
            gatsbyImageData(width: 1186, imgixParams: { auto: "" })
          }
        }
      }
    }
  }
`;

export default Index;
