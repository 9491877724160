import * as React from "react";
import { graphql } from "gatsby";
import { BigHeadingFragment } from "../generated/graphql";

import * as styles from "./bigHeading.module.scss";
import { ClassNames } from "@rocketmakers/armstrong-edge";

interface IProps {
  slice: BigHeadingFragment;
}

export const BigHeading: React.FC<IProps> = ({ slice }) => {
  return (
    <div className={ClassNames.concat("b-heading", styles.bigHeading)}>
      <h2>{slice.primary.heading}</h2>
    </div>
  );
};

export const query = graphql`
  fragment BigHeading on PrismicHomePageDataBodyBigHeading {
    primary {
      heading
    }
  }
`;
