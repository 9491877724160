import * as React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

import * as styles from "./columnLayout.module.scss";
import { Link } from "gatsby";

import arrowRight from '../assets/images/arrow_right.svg';

interface IProps {
  imageHeight?: number;
  noBorder?: boolean;
  maxColumns?: 3;
  items: {
    title?: string | null | undefined;
    description?: string | null | undefined;
    link?: string;
    image?: {
        gatsbyImageData?: IGatsbyImageData;
        alt?: string | null | undefined;
    } | null | undefined;
  }[]
}


const WrappingElement: React.FC<React.PropsWithChildren<{ url?: string }>> = ({
  url,
  children
}) => {
  if (url) {
    return (
      <Link to={url}>
        {children}
      </Link>
    );
  }
  return (
    <div>{children}</div>
  );
};

export const ColumnLayout: React.FC<IProps> = ({ maxColumns, imageHeight, noBorder, items }) => {
  return <div className={styles.columnLayout} data-noborder={noBorder} data-maxcolumns={maxColumns}>
    {items.map(item =>
      <WrappingElement key={item.title} url={item.link}>
        {item.image && item.image.gatsbyImageData &&
         <GatsbyImage
         style={{ height: imageHeight }}
         key={item.image.alt}
         image={item.image.gatsbyImageData}
         alt={item?.image.alt || "Stock image"}
       />
        }
        <h2>{item.title}</h2>
        <p>{item.description}</p>
        {item.link && <Link to={item.link}>Read more <img alt="Right arrow" src={arrowRight}/></Link>}
      </WrappingElement>  
    )}
  </div>;
};
