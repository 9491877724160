import * as React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { CopyBlockOptionalImageFragment } from "../generated/graphql";

import * as styles from "./copyBlockOptionalImage.module.scss";
import { ClassNames } from "@rocketmakers/armstrong-edge";
import arrowRight from '../assets/images/arrow_right.svg';

interface IProps {
  slice: CopyBlockOptionalImageFragment;
}

export const CopyBlockOptionalImage: React.FC<IProps> = ({ slice }) => {
  return (
    <div className={ClassNames.concat("fixed-width", styles.copyWithImage)}>
      {slice.primary.left_image && slice.primary.left_image.gatsbyImageData &&
          <GatsbyImage
            key={slice.primary.left_image?.alt}
            image={slice.primary.left_image?.gatsbyImageData}
            alt={slice.primary.left_image?.alt || "Image"}
          />
      }
      <div className={styles.copyBlock}>
        <h2>{slice.primary.text_image_title}</h2>
        <p>{slice.primary.text_image_body}</p>
        {slice.primary.text_image_site_page_link && <Link to={slice.primary.text_image_site_page_link}>Read more <img alt="Right arrow" src={arrowRight}/></Link>}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment CopyBlockOptionalImage on PrismicHomePageDataBodyCopyBlockOptionalImage {
    primary {
      left_image {
        gatsbyImageData
        alt
      }
      text_image_title
      text_image_body
      text_image_site_page_link
    }
  }
`;
